@font-face {
  font-family: "Shan";
  src: local("Shan"), url("./fonts/Shan/Shan.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

h1,
h3 {
  font-family: "Shan";
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Shan", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}
